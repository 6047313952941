'use strict';

var util = require('./util'),
    _debounce = require('./lib/lodash.debounce'),
    progress = require('./progress');

var timer = {
    id: null,
    clear: function () {
        if (this.id) {
            window.clearTimeout(this.id);
            delete this.id;
        }
    },
    start: function (duration, callback) {
        this.id = setTimeout(callback, duration);
    }
};

var minicart = {
    shown: false,
    init: function () {
        this.$el = $('#mini-cart');
        this.$link = this.$el.find('.mini-cart-link');
        this.$content = this.$el.find('.mini-cart-content');
        this.$products = this.$el.find('.mini-cart-products');
        this.$productsHeight = this.$products.height();
        this.$minicartWrapper = $('.minicart-wrapper');
        // this.$products.css('height', this.$productsHeight + 1); //hack to hide scrollbar if appropriate

        // events

        // ERV-273: Cart page - add message that product has been added to cart
        // Display the mini cart summary only on desktop - mobile tap redirects to the cart page
        var miniCartTotalADA = window.vbqUtils.isSR ? window.vbqUtils.breakpoint.is('fromDesktopLarge') : window.vbqUtils.breakpoint.is('fromDesktop');

        if (miniCartTotalADA) {
            this.$el.find('.mini-cart-total').on('click', function () {
                this.$link.attr('aria-expanded', (this.$link.attr('aria-expanded') == 'true' ? 'false' : 'true'));
                this.$content.toggleClass('active');

                if (this.$content.attr('aria-hidden') == 'true') {
                    this.$content.attr('aria-hidden', 'false').find('a, button').removeAttr('tabindex');
                }
                else {
                    // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
                    this.$content.attr('aria-hidden', 'true').find('a, button').attr('tabindex', '-1');
                }
            }.bind(this));
        }

        // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
        // initial minicart content state
        this.$content.find('a, button').attr('tabindex', '-1');

        $('.minicart-close').on('click', function (ev) {
            this.close();
        }.bind(this));

        window.vbqUtils.cache.$body.on('click', function () {
            if (this.shown) {
                this.close();
            }
        }.bind(this));

        window.vbqUtils.cache.$body.on('click', '#js-miniCartCheckoutButton', function () {
            $('#js-miniContinueShoppingButton, #js-miniCartCheckoutButton').addClass('disabled');
            progress.show($('#js-miniCartCheckoutButton'));
        });

        window.vbqUtils.cache.$body.on('click', '#js-miniContinueShoppingButton', function () {
            this.close();
        }.bind(this));
    },
    /**
     * @function
     * @description Shows the given content in the mini cart
     * @param {String} A HTML string with the content which will be shown
     * @param {Boolean} [isAddToCart=false] - Flag indicating if the product is being added to the cart
     */
    show: function (html, isAddToCart = false) {
        this.$el.html(html);
        // ERB 237 Gift box and message - prefix logic will break for >10 messages
        // prevent browser scrolling on add to cart, not needed with sticky header
        //util.scrollBrowser(0);
        this.init();

        // ECM24-58 - Not display header minicart from desktop on VBQ when product has been added
        if (!(isAddToCart && !window.vbqUtils.isSR && window.vbqUtils.breakpoint.is('fromDesktop'))) {
            this.$link.attr('aria-expanded', 'true');
            this.$content.addClass('active').attr('aria-hidden', 'false').find('a, button').removeAttr('tabindex');
            this.shown = true;
        }

        //ERB-52 International Shipping
        window.vbqUtils.priceDisplay.refresh();
    },
    /**
     * @function
     * @description Slides down and show the contents of the mini cart
     */
    /*slide: function () {
        timer.clear();
        // show the item
        this.$content.slideDown('slow');
        // after a time out automatically close it
        timer.start(6000, this.close.bind(this));
    },*/
    /**
     * @function
     * @description Closes the mini cart with given delay
     * @param {Number} delay The delay in milliseconds
     */
    close: function (delay) {
        this.$link.attr('aria-expanded', 'false');
        // ECM23-60: minicart content shouldn't be navigated by tab if it's hidden
        this.$content.removeClass('active').attr('aria-hidden', 'true').find('a, button').attr('tabindex', '-1');
        this.shown = false;
    },
    addedToCartDialog: function (options, product) {
        // ECM23-60 - Display added-to-cart dialog on desktop included on SR
        // ECM24-58 - Display added-to-cart dialog on VBQ for all viewports: exclude isCrossSell on desktop
        var isShowCartDialog = false;

        if (window.vbqUtils.isSR) {
            // For SR, show the dialog on mobile-desktop viewports
            isShowCartDialog = window.vbqUtils.breakpoint.is('mobileAndTabletAndDesktop');
        } else {
            // For VBQ, show the dialog on all viewports but exclude isCrossSell on desktop
            if (window.vbqUtils.breakpoint.is('fromDesktop')) {
                isShowCartDialog = product ? !product.isCrossSell : true;
            } else {
                isShowCartDialog = true;
            }
        }

        if (isShowCartDialog) {
            var defaultOptions = {
                autoOpen: false,
                draggable: false,
                modal: true,
                resizable: false,
                width: 'auto',
                closeOnEscape: true,
                closeText: window.Resources.CLOSE,
                classes: {
                    'ui-dialog': 'minicart-alert-dialog' //used to force styles
                },
                focus: function (e) {
                        // EADA-74 - Focus on close button on opening
                    $(e.target).closest('.ui-dialog').find('.ui-dialog-titlebar-close').focus();
                },
                open: function (e) {
                    // EADA-40 - The modal container is not labeled as such: .dialog() case
                    window.vbqUtils.jqDialogOpenAdjust($(e.target).closest('.ui-dialog'));

                    window.vbqUtils.cache.$body.css('overflow', 'hidden');

                    var $this = $(this);

                    // clean history
                    var $productName = $this.find('.js-mini-cart-alert-product-name').html('').addClass('hidden');
                    var $productSize = $this.find('.js-mini-cart-alert-product-size').addClass('hidden');
                    var $productSizeValue = $productSize.find('.js-mini-cart-alert-product-size-value').html('');
                    var $productColor = $this.find('.js-mini-cart-alert-product-color').addClass('hidden');
                    var $productColorValue = $productColor.find('.js-mini-cart-alert-product-color-value').html('');

                    if (product) {
                        if (product.name) {
                            $productName.html(product.name).html(product.name).removeClass('hidden');
                        }

                        if (product.size) {
                            $productSize.removeClass('hidden');
                            $productSizeValue.html(product.size);
                        }
                        if (product.color) {
                            $productColor.removeClass('hidden');
                            $productColorValue.html(product.color);
                        }
                    }

                    // ECM24-58 - Refont Add To Cart Popup
                    var $carousel = $this.find('.js-carousel');
                    if ($carousel && $carousel.length) {
                        $carousel.slick('refresh');
                    }
                },
                close: function () {
                    // activate body scroll
                    window.vbqUtils.cache.$body.css('overflow', '');
                }
            };

            window.$cartAlert = product && product.isCrossSell ? $('#js-miniCartAlert_crossSell').dialog($.extend(defaultOptions, options)) : $('#js-miniCartAlert').dialog($.extend(defaultOptions, options));

            window.$cartAlert.dialog('open');

            window.vbqUtils.cache.$body.off('click', '#js-minicart-alert-close').on('click', '#js-minicart-alert-close', function () {
                if (window.$cartAlert) {
                    window.$cartAlert.dialog('close');
                }
            });

            // change Tablet to Desktop, e.g.: in iPad
            window.vbqUtils.cache.$window.resize(_debounce(function () {
                if (window.vbqUtils.isSR) {
                    if (window.vbqUtils.breakpoint.is('fromDesktopLarge') && window.$cartAlert && window.$cartAlert.dialog('isOpen')) {
                        window.$cartAlert.dialog('close');
                    }
                } else {
                    if (window.vbqUtils.breakpoint.is('fromDesktop') && (product && product.isCrossSell) && window.$cartAlert && window.$cartAlert.dialog('isOpen')) {
                        window.$cartAlert.dialog('close');
                    }
                }
            }, 250));
        }
    }
};

module.exports = minicart;
