'use strict';

var dialog = require('../../dialog'),
    progress = require('../../progress'),
    minicart = require('../../minicart'),
    util = require('../../util');

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The jquery form element that contains the item quantity and ID data
 * @param {String} storeId, used for clickAndCollect and ultraFastDelivery
 * @param {Boolean} isUltraFastDelivery, used to distinguish between clickAndCollect and ultraFastDelivery
 * @param {String} postalCode, used with ultraFastDelivery so the postal code is added to the session
 * @returns {Deferred}
 */
var addItemToCart = function ($form, storeId, isUltraFastDelivery, postalCode) {
    var $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    if (postalCode === undefined) {
        postalCode = '';
    }

    return $.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize() + (storeId ? ('&storepickup=true&storeid=' + storeId + '&cc=' + !isUltraFastDelivery + '&ufd=' + isUltraFastDelivery + '&postalCode=' + postalCode) : '') // OCC-3 - Omnichannel Click and Collect
    }).then(function (response) {
        $form.find('.add-to-cart').removeAttr('disabled');

        // B2B2C site, mandatory authentication, redirect when the session customer is not authenticated
        if (response && response.indexOf('class="login-b2b2c-wrapper"') != -1) {
            window.location = window.Urls.home;
            return;
        }
        // handle error in the response
        else if (response.error || response == "") {
            progress.hide();
            throw new Error(response.error);
        }
        else {
            return response;
        }
    });
};

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e, storeId, isUltraFastDelivery, postalCode) {
    e.preventDefault();
    var $that = $(this),
        $form = $that.closest('form'),
        $wrapper = $that.closest('.js-pdp-main').length ? $that.closest('.js-pdp-main') : $that.closest('.js-product-set-item'),
        isCrossSell = $wrapper.hasClass('js-cross-sell-pdp'),
        // EPU-1 - New PDP design on VBQ - size list logic per brand
        $sizeList = !window.vbqUtils.isSR ? $wrapper.find('.js-size-list') : $wrapper.find('.size-list'),
        sizeSelected = !window.vbqUtils.isSR ? $sizeList.find('.js-size-radio').filter('.selected').attr('data-value') : $sizeList.find('.js-size-select').first().val(),
        colorSelected = $wrapper.find('.js-productColor').val(),
        productName = $wrapper.find('.js-product-detail-name').html();

    if ($that.hasClass('disabled')) {
        if (sizeSelected === null || sizeSelected === '' || sizeSelected === undefined) {
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $sizeList.parent().addClass('gl_has-error')
                    .attr('aria-describedby', $sizeList.parent().find('.size-select-error-msg').attr('id'))
                    .find('.js-size-radio').first().focus();
            }
            else {
                $sizeList.addClass('gl_has-error')
                    .find('.js-size-select')
                    .filter(':visible')
                    .attr('aria-describedby', $sizeList.find('.size-select-error-msg').attr('id'))
                    .focus();
            }
            return;
        }
        else {
            $that.removeClass('disabled');
            $sizeList.removeClass('gl_has-error');
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $sizeList.atremoveAttrtr('aria-describedby');
            }
            else {
                $sizeList.find('.js-size-select').removeAttr('aria-describedby');
            }
        }
    }

    $form.find('.add-to-cart').attr('disabled', 'disabled');
    progress.show($form.find('.add-to-cart'));

    if ($form.find('.add-to-cart').hasClass('hidden') && isUltraFastDelivery) {
        progress.show($form.find('.js-ultraFastDelivery').attr('disabled', 'disabled'));
    }

    window.vbqUtils.gtmUtils.addToCart($that);

    addItemToCart($form, storeId, isUltraFastDelivery, postalCode).then(function (response) {
        $form.find('.add-to-cart').removeAttr('disabled');

        if ($form.find('.add-to-cart').hasClass('hidden') && isUltraFastDelivery) {
            $form.find('.js-ultraFastDelivery').removeAttr('disabled');
        }

        // update add-to-cart form when the add-to-cart was done from the UFD dialog
        if (isUltraFastDelivery && storeId) {
            // update the postal code value in the header and mobile UFD links
            $('.js-ultraFastDeliveryPostalCodeValue').html(postalCode);
            // reload add-to-cart form for current size
            $('#product-content').find('.gl_size-button').filter('.selected').first().click();
        }

        var $uuid = $form.find('input[name="uuid"]'),
            mixPos = $form.parents('.js-pdp-main').data('pos') || null;

        // ERB-210 - Product detail page - implement iframe logic
        // If inside an iframe, call the parent window with data
        if ($('#main').hasClass('js-inIframe') || $('#main-mix').hasClass('js-inIframe')) {
            if (!window.location.origin) { // Some browsers (mainly IE) do not have this property, so we need to build it manually...
              window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? (':' + window.location.port) : '');
            }
            window.parent.postMessage(JSON.stringify({response: response, addToCart: true, hasUuid: ($uuid.length > 0 && $uuid.val().length > 0), mixPos: mixPos}), window.location.origin);
            progress.hide();
            return;
        }

        if ($uuid.length > 0 && $uuid.val().length > 0) {
            window.location.reload(true);
        }
        else {
            // do not close quickview if adding individual item that is part of product set
            // SG-TO-DO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            }

            if (response && response.indexOf('class="login-b2b2c-wrapper"') == -1) {
                // ECM24-58 - Refont Add To Cart Popup: exclude isCrossSell
                minicart.show(response, !isCrossSell);

                minicart.addedToCartDialog({
                    classes: {
                        'ui-dialog': isCrossSell ? 'minicart-cross-sell-alert-dialog' : 'minicart-alert-dialog' //used to force styles
                    },
                    close: function () {
                        // activate body scroll
                        window.vbqUtils.cache.$body.css('overflow', '');

                        // EADA-74 - Focus on modal opening element
                        $form.find('.add-to-cart').focus();
                    }
                },
                {
                    name: productName,
                    size: sizeSelected,
                    color: colorSelected,
                    isCrossSell: isCrossSell
                });
            }
        }

        // EADA-32 - Mini-cart alert role
        $('#mini-cart').find('.minicart-alert').removeClass('hidden');

        progress.hide();
    });
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#productset-list').find('.js-ps-pdpForm'),
        promises = [];

    var $addAllToCart = $('#add-all-to-cart').attr('disabled', 'disabled');
    var productAllName = $('.js-product-all-name').html();

    progress.show($addAllToCart);

    $productForms.each(function () {
        var $form = $(this);
        promises.push(addItemToCart($form));
    });

    $.when.apply($, promises)
        .done(function () {
            if (arguments && arguments.length > 0) {
                $.ajax({
                    url: Urls.miniCart,
                    success: function (response) {
                        // ECM24-58 - Refont Add To Cart Popup
                        minicart.show(response, true);

                        minicart.addedToCartDialog({
                            classes: {
                                'ui-dialog': 'minicart-alert-dialog minicart-add-all-alert-dialog' //used to force styles
                            }
                        },
                        {
                            name: productAllName,
                            isCrossSell: false
                        });
                    }
                });
            }
        })
        .always(function () {
            $addAllToCart.removeAttr('disabled');
            progress.hide();
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
module.exports = function () {
    // ECM-830 - Sku not added to cart
    // This line just empties the title, as the element is in availability.isml which we don't use
    //$('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    window.vbqUtils.cache.$body.on('click', '.js-product-detail-container .add-to-cart', addToCart);
    window.vbqUtils.cache.$body.on('click', '#add-all-to-cart', addAllToCart);
    window.vbqUtils.cache.$body.on('click', '.wishlist-wrapper .js-wishlistAddToCart', addToCart);
};
