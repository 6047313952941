'use strict';
//ECM-880 - Adyen 3DSv2 - file copied from int_adyen_overlay and modified for multiple payment partner logic + gulp minification process

function pad (number) {
    if (number < 10) {
        return '0' + number;
    }
    return number;
}


//Check the validity of checkout component
function componentDetailsValid (selectedMethod) {
    // ECM-880 - Adyen 3DSv2 - Adyen brand codes reverse mapping (see hppajax.isml)
    var mapping = {
        ADYEN_GIROPAY: 'giropay',
        ADYEN_KLARNADEBITRISK: 'directEbanking',
        ADYEN_IDEAL: 'ideal',
        //ADYEN_BANCONTACT: 'bcmc', // is handled by the credit card form
        ADYEN_EPS: 'eps',
        ADYEN_PAYPAL: 'paypal',
        // ADYEN_ALIPAY and ADYEN_WECHAT not needed
        // EKPM-1 - Add Klarna (Pay ¾ installment) as a mean of payment
        ADYEN_KLARNA: 'klarna_account'
    };

    //set data from components
    switch (mapping[selectedMethod]) {
        case 'ideal':
            var $issuer = $('#component_ideal').find('.adyen-checkout__dropdown__list').find('.adyen-checkout__dropdown__element--active');
            if ($issuer.length) {
                $('#dwfrm_adyPaydata_issuer').val($issuer.attr('data-value'));
                return true;
            }
            return false;

            /* old code for component version 3.4.0, KO with version 3.6.0
            if (window.idealComponent.componentRef.state.isValid) {
                $('#dwfrm_adyPaydata_issuer').val(window.idealComponent.componentRef.state.data.issuer);
            }
            return window.idealComponent.componentRef.state.isValid;
            */

        // ECM-880 - Adyen 3DSv2 - added missing EPS issuer
        case 'eps':
            $('#dwfrm_adyPaydata_issuer').val($('.eps-form').find('.js-issuersList').find('input[name="issuerId"]').filter(':checked').val());

            return true;

        /*
        case "klarna":
            if(klarnaComponent){
                if (klarnaComponent.componentRef.state.isValid) {
                    parseOpenInvoiceComponentData(klarnaComponent.componentRef.state);
                    if($('#ssnValue')){
                        $('#dwfrm_adyPaydata_socialSecurityNumber').val($('#ssnValue').val());
                    }
                }
                return klarnaComponent.componentRef.state.isValid;
            }
            else{
                //New Klarna integration is without component
                return true;
            }
            break;

        case "afterpay_default":
            if (afterpayComponent.componentRef.state.isValid) {
                parseOpenInvoiceComponentData(afterpayComponent.componentRef.state);
            }
            return afterpayComponent.componentRef.state.isValid;
            break;
        case "ratepay":
            $('#dwfrm_adyPaydata_dateOfBirth').val($("#ratepay_dob").val());
            $('#dwfrm_adyPaydata_gender').val($("#ratepay_gender").val());
            return true;
        */
        default:
            return true;
    }
}

function copyCardData(card) {
    // $('#dwfrm_billing_paymentMethods_creditCard_type').val(card.state.data.brand); // KO in Adyen component version 3.4.0
    $('#dwfrm_billing_paymentMethods_creditCard_type').val(window.AdyenCardBrand); // use custom variable, see billing.js
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val(card.state.data.encryptedCardNumber);
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val(card.state.data.encryptedExpiryMonth);
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val(card.state.data.encryptedExpiryYear);
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val(card.state.data.encryptedSecurityCode);
    $('#dwfrm_billing_paymentMethods_creditCard_owner').val(card.state.data.holderName);
    /* not wanted
    if(window.storeDetails){
        $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(window.storeDetails);
    }
    else {
        $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(false);
    }
    */
    $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(false); // security
}

function clearCardData() {
    $('#dwfrm_billing_paymentMethods_creditCard_type').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedCardNumber').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryMonth').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedExpiryYear').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_owner').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_saveCard').val(false); // security
}

// ECM-880 - Adyen 3DSv2 - multiple payment partner logic
exports.handleCC = function () {
    if (!window.CardValid) {
        window.AdyenCard.showValidation();
        return false;
    }
    clearCardData();
    $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID').val('');
    copyCardData(window.AdyenCard);

    return true;
};

exports.handleHPP = function () {
    clearCardData(); // security
    //var selectedMethod = $('[name="brandCode"]:checked').val();
    var selectedMethod = $('input[name="dwfrm_billing_paymentMethods_selectedPaymentMethodID"]:checked').val();
    return componentDetailsValid(selectedMethod);
};
