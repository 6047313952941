'use strict';

var password = require('../../helpers/password');
var cache = {},
    optional = Resources.OPTIONAL;

/**
 * Initializes the cache for the shipping create account form
 * @param {jQuery} $form - the jQuery object representing the form element
 * @returns {void}
 */
function initCache($form) {
    cache.$form = $form;
    cache.$passwordInput = cache.$form.find('.js-shipping-create_account_password');
    cache.$passwordInputConfirm = cache.$form.find('.js-shipping-create_account_password_confirm');
    cache.$createAcccountCheck = cache.$form.find('.js-shipping-create_account');
    cache.$createAccountWrapper = cache.$form.find('.js-shipping-create_account_fields');
    cache.$submitButton = cache.$form.find('button.shipping-continue-button');
}

/**
 * Updates the label text for password and confirm password fields
 */
function updateFieldsLabel() {
    var passwordLabel = cache.$passwordInput.closest('.gl_input-wrapper').find('label');
    var confirmPasswordLabel = cache.$passwordInputConfirm.closest('.gl_input-wrapper').find('label');

    // update label text
    passwordLabel.text((passwordLabel.text() || '').replace(' (' + optional + ')', '*'));
    // update label text
    confirmPasswordLabel.text((confirmPasswordLabel.text() || '').replace(' (' + optional + ')', '*'));
}

/**
 * Handles the change action for creating a new account during the shipping process
 * @param {Event} e - the event object
 * @returns {void}
 */
function onChangeAction(e) {
    var $this = $(e.currentTarget),
        isChecked = $this.is(':checked');
    
    // set checkbox value to "true" or "false", this is needed for SFCC backend "checked" attribute
    $this.val(isChecked ? true : false);

    if (isChecked) {
        cache.$passwordInput.addClass('customPasswordValidation required');
        cache.$createAccountWrapper.removeClass('hidden');
        // init password validation
        password.initCustomValidation();
    }
    else {
        cache.$passwordInput.removeClass('customPasswordValidation required');
        cache.$createAccountWrapper.addClass('hidden');
        // clear password fields to allow to submit shipping
        cache.$passwordInput.val('');
        cache.$passwordInputConfirm.val('');
    }
}

/**
 * Handles the submit action for the shipping form, specifically checking if the "Create Account" option is selected.
 * If the "Create Account" option is not selected, it clears the password fields to allow the form to be submitted.
 */
function onSubmitAction() {
    var isCreateAccountChecked = cache.$createAcccountCheck.is(':checked');

    if (!isCreateAccountChecked) {
        // security, clear password fields to allow to submit shipping
        cache.$passwordInput.val('');
        cache.$passwordInputConfirm.val('');
    }
}

/**
 * Initializes events for the create account checkbox
 */
function initEvents() {
    cache.$createAcccountCheck.on('change', onChangeAction);
    cache.$submitButton.on('click', onSubmitAction);
}

// ECM24-57 - Create an account checkout // Shipping Info
exports.init = function ($form) {
    // security, do nothing if form is not available
    if (!$form) {
        return;
    }

    initCache($form);
    updateFieldsLabel();
    initEvents();
};
