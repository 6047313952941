'use strict';

/**
 * Clears UTM parameters and last order date from the local storage.
 * This function removes the following items from local storage:
 * - utm_source
 * - utm_medium
 * - utm_campaign
 * - utm_content
 * - utm_term
 * - last_order_date
 */
function clearUtmParameters() {
    localStorage.removeItem('utm_source');
    localStorage.removeItem('utm_medium');
    localStorage.removeItem('utm_campaign');
    localStorage.removeItem('utm_content');
    localStorage.removeItem('utm_term');
    localStorage.removeItem('last_order_date');
}

/**
 * Retrieves the value of a specified UTM parameter from the current page's URL.
 * If the parameter is not found, returns a default value.
 * @param {string} paramName - The name of the UTM parameter to retrieve.
 * @param {string} defaultValue - The default value to return if the parameter is not found.
 * @returns {string} The value of the specified UTM parameter, or the default value if the parameter is not present.
 */
function getUtmParameter(paramName, defaultValue) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(paramName) || defaultValue;
}

/**
 * Stores UTM parameters from the URL into localStorage and marks the session as accessed.
 *
 * This function retrieves UTM parameters from the URL, stores them in localStorage, and
 * concatenates them with any existing values using a '|' separator. It also sets a flag
 * in sessionStorage to indicate that the page has been accessed.
 *
 * UTM parameters handled:
 * - utm_source
 * - utm_medium
 * - utm_campaign
 * - utm_content
 * - utm_term
 *
 * If a UTM parameter is not present in the URL, a default value is used:
 * - utm_source: 'direct'
 * - utm_medium: 'none'
 * - utm_campaign: 'none'
 * - utm_content: 'none'
 * - utm_term: 'none'
 *
 * @returns {void}
 */
function storeUtmValues() {
    const utmParams = {
        utm_source: getUtmParameter('utm_source', 'direct'),
        utm_medium: getUtmParameter('utm_medium', 'none'),
        utm_campaign: getUtmParameter('utm_campaign', 'none'),
        utm_content: getUtmParameter('utm_content', 'none'),
        utm_term: getUtmParameter('utm_term', 'none')
    };

    // ensure that only the UTM values from the URL are captured
    // avoid storing only default values
    if (utmParams.utm_source == 'direct'
        && utmParams.utm_medium == 'none'
        && utmParams.utm_campaign == 'none'
        && utmParams.utm_content == 'none'
        && utmParams.utm_term == 'none'
    ) {
        return;
    }

    // Concatenate UTM parameters with existing values using '|' as separator
    for (const key in utmParams) {
        const existingValue = localStorage.getItem(key);
        const newValue = existingValue ? `${existingValue}|${utmParams[key]}` : utmParams[key];
        localStorage.setItem(key, newValue);
    }
}

/**
 * Determines whether UTM parameters should be cleared based on the last order date.
 * Checks if the last order date stored in localStorage is older than seven days from the current date.
 * @returns {boolean} - Returns true if the UTM parameters should be cleared, otherwise false.
 */
function shouldClearUtmParameters() {
    const lastOrderDate = localStorage.getItem('last_order_date');

    if (lastOrderDate) {
        const lastOrderDateTime = new Date(lastOrderDate).getTime();
        const currentTime = new Date().getTime();
        const sevenDays = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
        return (currentTime - lastOrderDateTime) > sevenDays;
    }

    return false;
}

/**
 * Initializes the capture of UTM parameters by clearing existing UTM parameters if necessary
 * and then storing the current UTM values.
 * This function is typically used to ensure that UTM parameters are correctly tracked for analytics.
 */
function initUtmCapture() {
    if (shouldClearUtmParameters()) {
        clearUtmParameters();
    }

    storeUtmValues();
}

/**
 * Stores the current date and time as the last order date in local storage.
 * The date is stored in ISO 8601 format (UTC).
 */
function addLastOrderDate() {
    // Check if 'last_order_date' is already set
    if (!localStorage.getItem('last_order_date')) {
        const currentDate = new Date().toISOString(); // Get current date in UTC format
        localStorage.setItem('last_order_date', currentDate);
    }
}

/**
 * Saves UTM parameters from local storage to hidden form fields in the document.
 * This function retrieves UTM parameters (source, medium, campaign, content, term)
 * from the browser's local storage and populates corresponding hidden form fields
 * in the document with these values.
 * @returns {void}
 */
function saveUtmUriValues() {
    var document = window.document,
        // get utm uri elements
        utmElements = {
            utm_source: document.querySelectorAll('[name$="_utm__source"]'),
            utm_medium: document.querySelectorAll('[name$="_utm__medium"]'),
            utm_campaign: document.querySelectorAll('[name$="_utm__campaign"]'),
            utm_content: document.querySelectorAll('[name$="_utm__content"]'),
            utm_term: document.querySelectorAll('[name$="_utm__term"]')
        },
        // get utm uri values from local storage
        utmValues = {
            utm_source: localStorage.getItem('utm_source'),
            utm_medium: localStorage.getItem('utm_medium'),
            utm_campaign: localStorage.getItem('utm_campaign'),
            utm_content: localStorage.getItem('utm_content'),
            utm_term: localStorage.getItem('utm_term')
        };

    // save utm uri values to hidden form fields
    Object.keys(utmElements).forEach(function (key) {
        if (utmValues[key]) {
            utmElements[key].forEach(function (element) {
                // limit each value to the last 250 chars
                element.value = (utmValues[key] || '').slice(-250);
            });
        }
    });
}

module.exports = {
    init: function () {
        initUtmCapture();
    },
    setOrderDate: function () {
        addLastOrderDate();
    },
    saveUtmUriValues: saveUtmUriValues
};
